<script setup>
import {
  definePageMeta,
  computed,
  reactive,
  ref,
  defineAsyncComponent,
  watch,
  onMounted,
} from '#imports';
import { object, string } from 'yup';
import { Form as VeeForm } from 'vee-validate';
import { useFeedback } from '@/composables/useFeedback';
import { useAdult } from '@/composables/useAdult';
import { useUserStore } from '@/stores/user';
import { useModal } from 'vue-final-modal';
import useGoogleRecaptcha from '@/composables/useGoogleRecaptcha';
import { formatValues } from '@/utils/helpers';
import { useRouter } from 'nuxt/app';
import { useAuthStore } from '@/stores/auth';
import { USER_ROLES } from '@/configs/user-roles';
import { useFormHelp } from '@/composables/useFormHelp';
import { useSeo } from '@/composables/useSeo';

import UiMaskInput from '@/components/ui/form/UiMaskInput.vue';
import UiButton from '@/components/ui/button/UiButton.vue';
import UiInput from '@/components/ui/form/UiInput.vue';
import FormLayout from '@/components/layout/forms/FormLayout.vue';
import BackgroundBlock from '@/components/blocks/BackgroundBlock.vue';
import Adult from '@/components/sections/Adult.vue';
import LoginFinish from '@/components/sections/auth/LoginFinish.vue';
import SideView from '@/components/layout/views/SideView.vue';

const adultComposable = useAdult();
const { openFeedbackPopup } = useFeedback();
const userStore = useUserStore();
const { executeRecaptcha } = useGoogleRecaptcha();
const router = useRouter();
const authStore = useAuthStore();
const formHelpComposable = useFormHelp();

definePageMeta({
  middleware: ['is-authorized'],
});
useSeo({ title: 'Авторизация | Winston.ru' });

const isFinish = ref(false);
const isAdult = ref(false);

checkAdultSectionState();
function checkAdultSectionState(value) {
  isAdult.value = value || adultComposable.isAdult();
}
function openRestorePasswordPopup() {
  const { open, close } = useModal({
    modalId: 'restorePasswordPopup',
    component: defineAsyncComponent(
      () => import('@/components/popups/RestorePassword.vue'),
    ),
    attrs: {
      closeModal: () => {
        close();
      },
    },
  });

  open();
}

watch(
  () => authStore.isAuthorized,
  (value) => {
    if (!value) {
      isFinish.value = false;
    }
  },
);

// <editor-fold desc="FORM">
const formData = reactive({
  phone: '',
  password: '',
});

const phoneData = computed({
  get() {
    return formData.phone;
  },
  set(detail = {}) {
    formData.phone = detail.masked;
  },
});
async function sendForm(_, actions) {
  const { token } = await executeRecaptcha('submit');

  const data = {
    password: formData.password,
    phone: formatValues.phoneOnlyNumbers(formData.phone),
    captcha: token,
  };

  authStore
    .sendLogin(data)
    .then(() => {
      userStore.fetchUser().then((data) => {
        const role = data?.role;
        const id = data?.personalData?.mindboxId;

        if (role === USER_ROLES.NEW) {
          router.push('/auth/registration');
        } else {
          isFinish.value = true;

          sendAnalyticLoginSuccess();
          sendAnalyticAuthentication(id);
        }
      });
    })
    .catch(({ data }) => {
      sendAnalyticLoginError();

      formHelpComposable.checkErrors(data, actions.setErrors);
    });
}
// </editor-fold>

//<editor-fold desc="Validation rules">
const validationSchema = object({
  phone: string().required().trim().lengthPhone(11),
  password: string().required(),
});
// </editor-fold>

//<editor-fold desc="ANALYTIC">
import { commonAnalytic } from '@/analytic/common';
import { authAnalytic } from '@/analytic/auth';

function sendAnalyticAuthentication(id) {
  commonAnalytic.authentication(id);
}

function sendAnalyticLoginSuccess() {
  authAnalytic.loginSuccess();
}

function sendAnalyticLoginError() {
  authAnalytic.loginError();
}

function sendAnalyticRegistrationStart() {
  authAnalytic.registrationStart();
}

onMounted(() => {
  authAnalytic.loginStart();
});
//</editor-fold>
</script>

<template>
  <SideView
    class="login-page"
    :class="{
      'login-page--is-adult': !isAdult,
      'login-page--finish': isFinish,
    }"
    is-equal
    :is-collapsed="!isAdult || isFinish"
    :is-collapsed-start="!isAdult"
  >
    <template #background>
      <transition name="fade" mode="out-in">
        <UiButton
          v-if="!isFinish"
          theme="white-small"
          class="login-page__button login-page__button--help"
          text="Помощь"
          @click="openFeedbackPopup"
        />
      </transition>

      <BackgroundBlock
        class="login-page__bg"
        target-class="side-view__part--left"
      />
    </template>

    <template #left>
      <transition name="fade" mode="out-in">
        <Adult
          v-if="!isFinish"
          class="login-page__adult"
          @change="checkAdultSectionState"
        />

        <LoginFinish v-else class="login-page__finish" />
      </transition>
    </template>

    <template #right>
      <FormLayout class="login-page__form" heading="Вход">
        <template #content>
          <VeeForm
            v-slot="{ isSubmitting }"
            :validation-schema="validationSchema"
            autocomplete="off"
            @submit="sendForm"
          >
            <UiMaskInput
              v-model="phoneData"
              :mask="'+7 (###) ### ## ##'"
              unmasked
              name="phone"
              label="Телефон"
              hint="Введите номер телефона"
              class="login-page__item"
              input-mode="numeric"
            />

            <div class="login-page__item-wrapper">
              <UiInput
                v-model="formData.password"
                name="password"
                label="Пароль"
                :type="'password'"
                is-toggle-password
                class="login-page__item"
                autocomplete="current-password"
                is-error-text-indented
              />

              <UiButton
                theme="link"
                class="login-page__button login-page__button--link"
                text="Забыли пароль?"
                @click="openRestorePasswordPopup"
              />
            </div>

            <div class="login-page__footer">
              <UiButton
                type="submit"
                class="login-page__button login-page__button--submit"
                text="войти"
                :is-disabled="isSubmitting"
              />

              <UiButton
                theme="second"
                to="/auth/registration"
                class="login-page__button login-page__button--registration"
                text="зарегистрироваться"
                @click="sendAnalyticRegistrationStart"
              />
            </div>
          </VeeForm>
        </template>
      </FormLayout>
    </template>
  </SideView>
</template>

<style scoped lang="scss">
.login-page {
  $parent: &;

  &__button {
    &--help {
      position: absolute;
      top: em(60);
      left: em(60);
      z-index: 2;
      min-width: em(160);

      @include media-breakpoint-down(lg) {
        min-width: em(116);
      }

      @include media-breakpoint-down(sm) {
        top: em(15);
        right: em(15);
        left: auto;
        min-width: em(104);
      }
    }

    &--link {
      position: absolute;
      right: 0;
      bottom: em(-4);

      @include media-breakpoint-down(lg) {
        bottom: 0;
      }

      @include media-breakpoint-down(sm) {
        bottom: em(-4);
      }
    }

    &--submit {
      width: calc(50% - #{em(8)});

      @include media-breakpoint-down(sm) {
        width: em(160);
      }
    }

    &--registration {
      width: calc(50% - #{em(8)});

      @include media-breakpoint-down(sm) {
        width: auto;
      }
    }
  }

  //&__left {
  //  @include hide-scroll;
  //
  //  overflow: hidden auto;
  //  position: relative;
  //  display: flex;
  //  flex-direction: column;
  //  flex-shrink: 0;
  //  align-items: center;
  //  width: 50%;
  //  padding-top: em(330);
  //  height: 100%;
  //  transition: width $time-very-slow;
  //
  //  @include media-breakpoint-down(lj) {
  //    padding-top: 0;
  //    justify-content: center;
  //  }
  //
  //  @include media-breakpoint-down(sm) {
  //    width: 100%;
  //    height: em(276);
  //    padding-top: em(58);
  //    overflow: initial;
  //  }
  //}
  //
  //&__right {
  //  @include hide-scroll;
  //
  //  display: flex;
  //  flex-direction: column;
  //  flex-shrink: 0;
  //  align-items: center;
  //  justify-content: center;
  //  width: 50%;
  //  overflow: hidden auto;
  //
  //  @include media-breakpoint-down(sm) {
  //    width: 100%;
  //    height: auto;
  //    align-items: initial;
  //    justify-content: initial;
  //    flex-grow: 1;
  //    overflow: initial;
  //  }
  //}

  &__adult {
    //width: 100%;
  }

  &__finish {
    //width: 100%;
    //
    //@include media-breakpoint-down(sm) {
    //  align-self: flex-start;
    //  flex-grow: 1;
    //}
  }

  &__item-wrapper {
    position: relative;
  }

  &__footer {
    display: flex;
    gap: em(16);
    align-items: center;
    margin-top: em(48);

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  //&--is-adult {
  //  @include media-breakpoint-down(sm) {
  //    height: 100vh;
  //    overflow: hidden;
  //  }
  //
  //  #{$parent} {
  //    &__left {
  //      width: 100%;
  //      height: 100vh;
  //    }
  //  }
  //}
  //
  //&--finish {
  //  @include media-breakpoint-down(sm) {
  //    height: 100vh;
  //    overflow: hidden;
  //  }
  //
  //  #{$parent} {
  //    &__left {
  //      @include media-breakpoint-up(sm) {
  //        width: 100%;
  //        height: 100vh !important;
  //      }
  //
  //      @include media-breakpoint-down(sm) {
  //        justify-content: flex-start;
  //      }
  //    }
  //  }
  //}
}
</style>
